import { Figure } from '../components/core/figure';

const ListRenderer = (props: any) => {
  const { children, type } = props;
  switch (true) {
    case type === 'number':
      return <ul className='list-decimal ml-6 my-4'>{children}</ul>;
    default:
      return <ul className='list-disc ml-6 my-4'>{children}</ul>;
  }
};

export const serializers: any = {
  marks: {
    strong: ({ children }: any) => {
      return <p className='font-universBoldCondensed'>{children}</p>;
    },
    link: ({ mark, children }: any) => {
      const { href } = mark;
      return (
        <a className='underline' href={href} target='_blank' rel='noopener'>
          {children}
        </a>
      );
    },
  },
  list: ListRenderer,
  types: {
    block(props: any) {
      switch (props.node.style) {
        case 'h2':
          return (
            <h2 className='text-titleSmallMobile sm:text-titleSmallTablet lg:text-titleSmall font-universBoldCondensed pb-2'>
              {props.children}
            </h2>
          );

        case 'h3':
          return (
            <h3 className='text-xl sm:text-titleSmallMobile font-universBoldCondensed pb-2'>
              {props.children}
            </h3>
          );

        default:
          return <p className='pb-6'>{props.children}</p>;
      }
    },
    mainImage: Figure,
  },
};
