import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { sanityConfig } from '../../lib/sanityConfig';

export const Figure = ({ node }: any) => {
  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  const gatsbyImageData = getGatsbyImageData(node, {}, sanityConfig);

  return (
    <figure className="mb-12">
      {gatsbyImageData && <GatsbyImage image={gatsbyImageData} alt={node.altText} />}
      <figcaption>{node.caption}</figcaption>
    </figure>
  );
};
