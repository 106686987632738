import React from 'react';
import { serializers } from '../../lib/sanityHelpers';
import BasePortableText from '@sanity/block-content-to-react';
import { sanityConfig } from '../../lib/sanityConfig';

interface BlockTextProps {
  blocks: any;
}

const PortableText = ({ blocks }: BlockTextProps) => {
  return <BasePortableText blocks={blocks} serializers={serializers} {...sanityConfig} />;
};

export default PortableText;
